import { Facebook, Google } from "@mui/icons-material";
import { Avatar, Button, Container, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetUserProfileMutation,
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
} from "../../services/user";
import { homeActions } from "../../store";
import FeedbackDialog from "../../utils/FeedbackDialog";
import {
  DATA_KEY,
  googleWebClientID,
  ImiPathsUrls,
  legalContracts,
} from "../../utils/ImiUtilData";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import AppearanceDialog from "./AppearanceDialog";

const ImiProfileCard = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "80%",
      maxHeight: 435,
    },
  }));

  const classes = useStyles();

  const styleConfig = {
    tile: {
      width: "100%",
      height: getElementHeight(140),
      display: "block",
      paddingLeft: getElementHeight(36.5),
    },
    tileTitle: {
      display: "flex",
      justifyContent: "flex-start",
      textTransform: "none",
      fontSize: getElementHeight(23),
      textAlign: "left",
    },
    tileSubTitle: {
      display: "flex",
      flexDirection: "row",
      textTransform: "none",
      fontSize: getElementHeight(20),
      textAlign: "left",
    },
  };
  //#endregion

  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const userData = useSelector((state) => state.home.userData);
  const themeMode = useSelector((state) => state.home.themeMode);
  const loginToken = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    //localStorage.removeItem(DATA_KEY);

    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      //console.log(_userData);

      if (_userData) {
        //dispatch(homeActions.setUserToken(_userData));

        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
          })
        );
      }
    }
  }, []);

  //#endregion

  //#region THEME DIALOG
  const [openThemeDialog, setOpenThemeDialog] = useState(false);

  const onAppearanceBtnClick = () => {
    setOpenThemeDialog(true);
  };

  const onCancelBtnClick = (newValue) => {
    setOpenThemeDialog(false);
  };

  let themeDialog = (
    <AppearanceDialog
      classes={{
        paper: classes.paper,
      }}
      keepMounted
      open={openThemeDialog}
      onClose={onCancelBtnClick}
      value={themeMode}
    />
  );
  //#endregion

  //#region USER PROFILE CONTENT

  const responseFacebook = (response) => {
    //console.log(response);

    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setOpenFeedbackDialog(true);
    }
  };

  const responseGoogle = (response) => {
    if (response.tokenId) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(response.tokenId);
    } else {
      setOpenFeedbackDialog(true);
    }
  };

  const termsCond_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.termsCondUrl,
      })
    );
    dispatch(homeActions.setNavBarStatus(true));
    console.log(location);
    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  const privacyPolicy_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.privacyPolicyUrl,
      })
    );
    dispatch(homeActions.setNavBarStatus(true));
    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  const signOut_BtnClick = () => {
    dispatch(homeActions.setUserToken(null));
    dispatch(homeActions.setUserData(null));

    //DELETE FROM LOCAL STORAGE
    localStorage.removeItem(DATA_KEY);
  };

  const mySubs_BtnClick = () => {
    //history.push(ImiPathsUrls.subs);
    navigate(ImiPathsUrls.subs);
  };

  let userProfileContent;

  if (userData) {
    userProfileContent = (
      <Fragment>
        <Container
          style={{
            display: "flex",
            height: getElementHeight(130),
            marginTop: getElementHeight(10),
            justifyContent: "center",
          }}
        >
          <Avatar
            variant="square"
            style={{
              width: getElementHeight(130),
              height: "auto",
            }}
            src={userData.image}
          />
        </Container>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: getElementHeight(10),
            marginTop: getElementHeight(10),
            fontWeight: "bold",
            fontSize: getElementHeight(25),
          }}
        >
          {userData.name}
        </Typography>
        <div style={{ height: getElementHeight(60) }}></div>
        <Divider />
        <Button onClick={mySubs_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            My Subscriptions
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Manage Your Subscriptions"}
          </Typography>
        </Button>
        <Divider />
        <Button onClick={onAppearanceBtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            Appearance
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Choose your light or dark theme preference"}
          </Typography>
        </Button>
        <Divider />
        <Button onClick={termsCond_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            {"Terms & Conditions"}
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Check out our terms & conditions"}
          </Typography>
        </Button>
        <Divider />
        <Button onClick={privacyPolicy_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            Privacy Policy
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Check out our privacy policy"}
          </Typography>
        </Button>
        <Divider />

        <Button
          color="primary"
          onClick={signOut_BtnClick}
          style={{
            backgroundColor: "rgba(199,18,57,255)",
            color:'white',
            display: "flex",
            flexDirection: "row",
            marginTop: getElementHeight(40),
            marginLeft: "auto",
            marginRight: "auto",
            textTransform: "none",
            fontSize: getElementHeight(20),
          }}
        >
          Sign Out
        </Button>
      </Fragment>
    );
  } else {
    userProfileContent = (
      <Fragment>
        <div style={{ height: getElementHeight(20) }}></div>

        <FacebookLogin
          appId="421341401639640"
          autoLoad={false}
          callback={responseFacebook}
          isMobile={true}
          disableMobileRedirect={true}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "rgba(75, 111, 167,255)",
                display: "flex",
                flexDirection: "row",
                width: getElementHeight(480),
                height: getElementHeight(80),
                //display : 'flex',
                marginTop: getElementHeight(20),
                //marginBottom : getElementWidth(20),
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
              }}
              startIcon={<Facebook sx={{ color: "white" }} />}
            >
              <Typography
                color="white"
                fontSize={getElementHeight(28)}
                fontWeight="bold"
                textTransform="none"
              >{`Facebook`}</Typography>
            </Button>
          )}
        />

        {/* <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button> */}

        <GoogleLogin
          clientId={googleWebClientID}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "rgba(214, 72, 34,255)",
                display: "flex",
                flexDirection: "row",
                width: getElementHeight(480),
                height: getElementHeight(80),
                //display : 'flex',
                marginTop: getElementHeight(20),
                //marginBottom : getElementWidth(20),
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
              }}
              startIcon={<Google sx={{ color: "white" }} />}
            >
              <Typography
                color="white"
                fontSize={getElementHeight(28)}
                fontWeight="bold"
                textTransform="none"
              >{`Google`}</Typography>
            </Button>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          //cookiePolicy={'single_host_origin'}
        />

        <div style={{ height: getElementHeight(40) }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            display="inline"
            style={{
              textTransform: "none",
              fontSize: getElementHeight(20),
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            By Logging in, you agree to our&nbsp;
          </Typography>
          <Typography
            style={{
              color: "rgba(199,18,57,255)",
              textTransform: "none",
              fontSize: getElementHeight(20),
              textAlign: "center",
              fontWeight: "500",
            }}
            display="inline"
            onClick={termsCond_BtnClick}
          >
            {" "}
            Terms and Conditions
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            display="inline"
            style={{
              textTransform: "none",
              fontSize: getElementHeight(20),
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            and acknowledge that you have read the&nbsp;
          </Typography>
          <Typography
            style={{
              color: "rgba(199,18,57,255)",
              textTransform: "none",
              fontSize: getElementHeight(20),
              textAlign: "center",
              fontWeight: "500",
            }}
            display="inline"
            onClick={privacyPolicy_BtnClick}
          >
            {"Privacy Policy."}
          </Typography>
        </div>

        <div style={{ height: getElementHeight(60) }}></div>

        <Divider />

        <Button onClick={onAppearanceBtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            Appearance
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            Choose your light or dark theme preference
          </Typography>
        </Button>

        <Divider />

        <Button onClick={termsCond_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            {"Terms & Conditions"}
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Check out our terms & conditions"}
          </Typography>
        </Button>

        <Divider />

        <Button onClick={privacyPolicy_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            Privacy Policy
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Check out our privacy policy"}
          </Typography>
        </Button>

        <Divider />
      </Fragment>
    );
  }

  //#endregion

  //#region USER TOKEN APIS STATES
  //USER TOKEN FB
  const [
    getUserTokenFB,
    {
      isLoading: loadingFBTokenAPI,
      isSuccess: fbTokenAPISuccess,
      data: fbTokenAPIData,
      error: fbTokenAPIError,
    },
  ] = useGetUserTokenFBMutation();

  //USER TOKEN GOOGLE
  const [
    getUserTokenGoogle,
    {
      isLoading: loadingGoogleTokenAPI,
      isSuccess: googleTokenAPISuccess,
      data: googleTokenAPIData,
      error: googleTokenAPIError,
    },
  ] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [
    getUserProfile,
    {
      isLoading: loadingUserProfileAPI,
      isSuccess: userProfileAPISuccess,
      data: userProfileAPIData,
      error: userProfileAPIError,
    },
  ] = useGetUserProfileMutation();

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (loadingFBTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenAPISuccess) {
      loginToken.current = fbTokenAPIData.access_token;

      getUserProfile(fbTokenAPIData.access_token);
      setTokenAPIStateIndex(3);
    } else if (fbTokenAPIError) {
      //console.log(fbTokenAPIError);

      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (loadingGoogleTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenAPISuccess) {
      loginToken.current = googleTokenAPIData.access_token;

      getUserProfile(googleTokenAPIData.access_token);
      setTokenAPIStateIndex(3);
    } else if (googleTokenAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (loadingUserProfileAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileAPISuccess) {
      dispatch(homeActions.setUserToken(loginToken.current));
      dispatch(
        homeActions.setUserData({
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
        })
      );

      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({
          token: loginToken.current,
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
        })
      );

      setTokenAPIStateIndex(0);
    } else if (userProfileAPIError) {
      console.log(userProfileAPIError);
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Login Error",
          }}
        />
      );
    }
  } else {
    tokenAPIStateContent = null;
  }
  //#endregion

  //#region FEEDBACK DIALOG CONTENT
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  let feedbackDialogContent;

  if (openFeedbackDialog) {
    feedbackDialogContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          setOpenFeedbackDialog(false);
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    feedbackDialogContent = null;
  }

  //#endregion

  return (
    <Fragment>
      {userProfileContent}
      {themeDialog}
      {/* {paymentDialog} */}
      {tokenAPIStateContent}
      {feedbackDialogContent}
    </Fragment>
  );
};

export default ImiProfileCard;
