import { Menu, PlayCircleOutline } from "@mui/icons-material";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { homeActions } from "../../store";
import { ImiCategoryTypes, ImiPathsUrls } from "../../utils/ImiUtilData";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";

const ImiImageItem2 = ({ item, type, width, ...restProps }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    middleIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "-ms-transform": "translate(-50%, -50%)",
    },
  }));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  const openGame = () => {
    window.open(item.item_url);
  };

  return (
    <ImageListItem {...restProps} style={{ marginRight: getElementHeight(15) }}>
      <input
        type="image"
        src={item.item_cover_image}
        alt={item.item_name}
        onClick={openGame}
        height="100%"
        width={width}
      />
      {type === ImiCategoryTypes.VIDEO && (
        <div className={classes.middleIcon}>
          <PlayCircleOutline fontSize={"large"} color="primary.light" />
        </div>
      )}
      {type === ImiCategoryTypes.BETA && (
        <ImageListItemBar
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
          actionIcon={
            <IconButton aria-label={`star ${item.item_name}`}>
              <Menu color="primary.light" />
            </IconButton>
          }
        />
      )}
    </ImageListItem>
  );
};

export default ImiImageItem2;
