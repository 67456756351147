import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getElementHeight } from "../../utils/ScreenManager";

const UnSubConfirmationDialog = ({ onClose, open }) => {
  const onActionBtnClick = (state) => {
    onClose(state);
  };

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle
        style={{
          fontSize: getElementHeight(35),
          textAlign: "left",
          fontWeight: "100",
        }}
      >
        Are You Sure ?
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          style={{
            textTransform: "none",
            fontSize: getElementHeight(30),
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          You will lose all the rewards and will be removed from the leaderboard
          if you unsubscribe.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onActionBtnClick(true);
          }}
          color="primary"
          autoFocus
        >
          OK
        </Button>
        <Button
          onClick={() => {
            onActionBtnClick(false);
          }}
          color="primary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnSubConfirmationDialog;
