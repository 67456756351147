import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Pagination,
  Skeleton,
  stepConnectorClasses,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Fragment } from "react";
import { useGetItemListQuery } from "../../services/catalog";
import { ImiCategoryTypes } from "../../utils/ImiUtilData";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import ImiImageItem from "./ImiImageItem";

function getCardDimensions(type, Y) {
  if (type === ImiCategoryTypes.PROMO_GAME) {
    return {
      height: getElementHeight(500),
      width: getElementHeight(360),
    };
  } else {
    return {
      height: getElementHeight(275),
      width: getElementHeight(200),
    };
  }
}

const ImiImageList = ({ id, title, type }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  const styleConfig = {
    skelton: { marginRight: getElementHeight(15) },
  };
  //#endregion

  //#region HOOKS & APIS

  //const [windowX,windowY] = useWindowSize();
  const [page, setPage] = useState(1);

  //ITEM LIST API
  const { data, error, isLoading } = useGetItemListQuery({
    id: id,
    page: page - 1,
    size: 10,
  });

  //#endregion

  //#region IMAGE LIST API CONTENT
  const dimensions = getCardDimensions(type);
  const rowHeight = dimensions.height;
  const rowWidth = dimensions.width;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  let pageCount = 0;

  let imageListAPIContent;
  if (isLoading) {
    imageListAPIContent = (
      <div
        style={{
          display: "flex",
        }}
      >
        {[
          <ImageListItem key={0}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
          <ImageListItem key={1}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
          <ImageListItem key={2}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
          <ImageListItem key={3}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
          <ImageListItem key={4}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
          <ImageListItem key={5}>
            <Skeleton
              variant="rect"
              style={{ ...styleConfig.skelton }}
              width={rowWidth}
              height={rowHeight}
            />
          </ImageListItem>,
        ]}
      </div>
    );
  } else if (error) {
    imageListAPIContent = (
      <Typography variant="h6">Something went wrong!</Typography>
    );
  } else {
    if (data?.dataRows) {
      pageCount = data.totalPages;
      let gameList = [
        ...new Map(
          data.dataRows.map((item) =>
            item.item_type === "GAME"
              ? [item.additional_info.game_id, item]
              : [item.item_url,item]
          )
        ).values(),
      ];
      imageListAPIContent = (
        <Box
          style={{
            display: "flex",
          }}
        >
          {gameList.map((item) => (
            <ImiImageItem
              item={item}
              type={type}
              width={rowWidth}
              key={
                item.item_type === "GAME"
                  ? item.additional_info.game_id
                  : item.item_url
              }
            />
          ))}
        </Box>
      );
    } else {
      imageListAPIContent = (
        <Typography variant="h6">Something went wrong!</Typography>
      );
    }
  }
  //#endregion

  return (
    <Fragment>
      <Box
        style={{
          height: getElementHeight(100),
          display: "flex",
          alignItems: "flex-end",
          paddingBottom: getElementHeight(13),
          fontSize: "0px",
        }}
      >
        <Typography
          color="text.primary"
          style={{
            marginLeft: getElementHeight(15),
            fontSize: getElementHeight(25),
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          overflow: "hidden",
          fontSize: "0px",
        }}
      >
        <ImageList
          style={{
            flexWrap: "nowrap",
            width: "100%",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          cols={0}
          rowHeight={rowHeight}
        >
          {imageListAPIContent}
        </ImageList>
        {pageCount > 1 && (
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            size="small"
          />
        )}
      </Box>
    </Fragment>
  );
};

export default ImiImageList;
