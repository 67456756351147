import {
  Box,
  Container,
  ImageList,
  ImageListItem,
  Pagination,
  Skeleton,
  stepConnectorClasses,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Fragment } from "react";
import { useGetItemListQuery } from "../../services/catalog";
import { ImiCategoryTypes } from "../../utils/ImiUtilData";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import ImiImageItem from "./ImiImageItem";
import ImiImageItem2 from "./ImiImageItem2";
import Image1 from "../../assets/MoreFun/Image1.png";
import Image2 from "../../assets/MoreFun/Image2.png";
import Image3 from "../../assets/MoreFun/Image3.png";

function getCardDimensions(type, Y) {
  if (type === ImiCategoryTypes.PROMO_GAME) {
    return {
      height: getElementHeight(500),
      width: getElementHeight(360),
    };
  } else {
    return {
      height: getElementHeight(275),
      width: getElementHeight(200),
    };
  }
}

const ImiImageList2 = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  //#endregion

  //#region HOOKS & APIS

  //#endregion

  //#region IMAGE LIST API CONTENT
  const dimensions = getCardDimensions(ImiCategoryTypes.GAME);
  const rowHeight = dimensions.height;
  const rowWidth = dimensions.width;

  const imageList = [
    {
      id: 1,
      item_url: "https://seasonalgames.space/",
      item_cover_image: Image2,
      item_name: "image2",
    },
    {
      id: 2,
      item_url: "https://arena.dgl.lk/cadlslk/campaign/game/newyearspecial",
      item_cover_image: Image1,
      item_name: "image1",
    },
    {
      id: 3,
      item_url: "https://seasonalgames.space/",
      item_cover_image: Image3,
      item_name: "image3",
    },
  ];

  let imageListAPIContent = (
    <Box
      style={{
        display: "flex",
      }}
    >
      {imageList.map((item) => (
        <ImiImageItem2
          item={item}
          type={ImiCategoryTypes.GAME}
          width={rowWidth}
          key={item.id}
        />
      ))}
    </Box>
  );

  //#endregion

  return (
    <Fragment>
      <Box
        style={{
          height: getElementHeight(100),
          display: "flex",
          alignItems: "flex-end",
          paddingBottom: getElementHeight(13),
          fontSize: "0px",
        }}
      >
        <Typography
          color="text.primary"
          style={{
            marginLeft: getElementHeight(15),
            fontSize: getElementHeight(25),
            fontWeight: "bold",
          }}
        >
          {"For More Fun"}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          overflow: "hidden",
          fontSize: "0px",
        }}
      >
        <ImageList
          style={{
            flexWrap: "nowrap",
            width: "100%",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          cols={0}
          rowHeight={rowHeight}
        >
          {imageListAPIContent}
        </ImageList>
      </Box>
    </Fragment>
  );
};

export default ImiImageList2;
