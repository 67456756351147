import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { getElementHeight } from "../../utils/ScreenManager";

const ImiSubItem = (props) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  //#endregion

  const { item, onUnSub } = props;
  console.log(item);
  const unSubHandler = () => {
    onUnSub(item.uuid);
  };

  return (
    <Card
      style={{
        display: "flex",
        width: "100%",
        height: getElementHeight(240),
        marginTop: getElementHeight(20),
        paddingLeft: getElementHeight(15),
        paddingRight: getElementHeight(15),
      }}
    >
      <CardMedia
        style={{
          width: getElementHeight(170),
          height: "100%",
          borderRadius: 0,
        }}
        image={item.cover_image}
        title={item.name}
        sx={{ backgroundColor: "background.default" }}
      />
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: getElementHeight(15),
          padding: 0,
          width: getElementHeight(530),
          height: getElementHeight(240),
          position: "relative",
        }}
        sx={{ backgroundColor: "background.default" }}
      >
        <Typography
          color="text.primary"
          style={{
            fontWeight: "bold",
            fontSize: getElementHeight(26),
          }}
        >
          {item.name}
        </Typography>
        <Typography
          color="text.secondary"
          style={{
            fontSize: getElementHeight(22),
          }}
          noWrap={true}
        >
          {item.description}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={unSubHandler}
          style={{
            fontSize: getElementHeight(18),
            textTransform: "none",
            position: "absolute",
            bottom: 0,
            right: getElementHeight(20),
          }}
          disableElevation
        >
          Unsubscribe
        </Button>
      </CardContent>
    </Card>
  );
};

export default ImiSubItem;
