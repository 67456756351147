import {
  BottomNavigation,
  BottomNavigationAction,
  Slide,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ImiPathsUrls } from "../../utils/ImiUtilData";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Profile } from "../../assets/profile.svg";

const HideOnCommand = (props) => {
  const { children, trigger } = props;

  return (
    <Slide appear={false} direction="up" in={trigger}>
      {children}
    </Slide>
  );
};

const ImiBottomNav = (props) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  const styleConfig = {
    bottomNavBar: {
      width: getElementWidth(715),
      position: "fixed",
      bottom: 0,
      justifyContent: "space-between",
      height: getElementHeight(100),
    },
    label: {
      fontSize: getElementHeight(18),
    },
    icon: {
      fontSize: getElementHeight(45),
    },
  };
  //#endregion

  //#region HOOKS
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ImiPathsUrls.profile) {
      setSelectedValue(ImiPathsUrls.profile);
    } else if (location.pathname === ImiPathsUrls.subs) {
      setSelectedValue(ImiPathsUrls.profile);
    } else {
      setSelectedValue(ImiPathsUrls.home);
    }
  }, [location]);

  const [selectedValue, setSelectedValue] = useState(
    location.pathname !== ImiPathsUrls.home
      ? ImiPathsUrls.profile
      : ImiPathsUrls.home
  );
  //#endregion

  return (
    <HideOnCommand trigger={props.trigger}>
      <BottomNavigation
        showLabels
        value={selectedValue}
        style={{ ...styleConfig.bottomNavBar }}
      >
        <BottomNavigationAction
          label={<Typography style={{ ...styleConfig.label }}>Home</Typography>}
          icon={
            <Home
              fill={
                selectedValue === ImiPathsUrls.home
                  ? "rgba(199,18,57,255)"
                  : "rgba(126,126,126,255)"
              }
              style={{ ...styleConfig.icon }}
            />
          }
          component={NavLink}
          to={ImiPathsUrls.home}
          value={ImiPathsUrls.home}
        />
        {/* <BottomNavigationAction
          label={
            <Typography style={{ ...styleConfig.label }}>Search</Typography>
          }
          icon={<Search style={{ ...styleConfig.icon }} />}
          component={NavLink}
          to={ImiPathsUrls.search}
          value={ImiPathsUrls.search}
        /> */}
        <BottomNavigationAction
          label={
            <Typography style={{ ...styleConfig.label }}>Profile</Typography>
          }
          icon={
            <Profile
              fill={
                selectedValue === ImiPathsUrls.profile
                  ? "rgba(199,18,57,255)"
                  : "rgba(126,126,126,255)"
              }
              style={{ ...styleConfig.icon }}
            />
          }
          component={NavLink}
          to={ImiPathsUrls.profile}
          value={ImiPathsUrls.profile}
        />
      </BottomNavigation>
    </HideOnCommand>
  );
};

export default ImiBottomNav;
