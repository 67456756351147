import { grey } from "@mui/material/colors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "./hooks/useWindowSize";
import { ImiPathsUrls, IMIThemeMode } from "./utils/ImiUtilData";
import { Navigate, Route, Routes } from "react-router-dom";
import ImiAppBars from "./components/AppBars";
import { screenSizeData } from "./utils/ScreenManager";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ImiHome from "./components/Home";
import ImiProfile from "./components/Profile";
import ImiGameFrame from "./components/Game/ImiGameFrame";
import ImiSearch from "./components/Search";
import PageNotFound from "./utils/PageNotFound";
import ImiSubscriptions from "./components/Subscriptions";
import { ReactComponent as Home } from "./assets/home.svg";
import { ReactComponent as Profile } from "./assets/profile.svg";

export const ThemeColors = {
  backgroundColor: {
    light: "rgba(244, 244, 244, 255)",
    //dark : 'rgba(0, 0, 0, 0.54)'
    dark: "rgba(20, 28, 41, 255)",
  },
  appBarColor: {
    light: "rgba(255,255,255,255)",
    dark: "rgba(0,0,0,255)",
  },
  primaryTextColor: {
    light: "rgba(0, 0, 0, 255)",
    dark: "rgba(255,255,255,255)",
  },
  secondaryTextColor: {
    light: grey[700],
    dark: grey[500],
  },
  bottomNavColor: {
    light: "rgba(255,255,255,255)",
    //dark : grey[800]
    dark: "rgba(0,0,0,255)",
  },
  bottomNavSelectColor: {
    light: "rgba(199, 18, 57,255)",
    dark: "rgba(199, 18, 57,255)",
  },
  backDropColor: {
    light: "rgba(0,0,0,255)",
    dark: "rgba(33,33,33,100)",
  },
};

function App() {
  //const [windowX,windowY] = useWindowSize();
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] =
    useWindowSize();

  const themeMode = useSelector((state) => state.home.themeMode);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: "rgba(199,18,57,255)",
            light: "rgba(199,18,57,255)",
            dark: "rgba(199,18,57,255)",
          },
          secondary: {
            main: "rgba(199,18,57,255)",
          },
          icon: {
            main:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.primaryTextColor.light
                : ThemeColors.primaryTextColor.dark,
          },
          background: {
            default:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.backgroundColor.light
                : ThemeColors.backgroundColor.dark,
            paper:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.backgroundColor.light
                : ThemeColors.backgroundColor.dark,
          },
          text: {
            primary:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.primaryTextColor.light
                : ThemeColors.primaryTextColor.dark,
            secondary:
              themeMode === IMIThemeMode.LIGHT
                ? ThemeColors.secondaryTextColor.light
                : ThemeColors.secondaryTextColor.dark,
          },
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor:
                  themeMode === IMIThemeMode.LIGHT
                    ? ThemeColors.appBarColor.light
                    : ThemeColors.appBarColor.dark,
              },
            },
          },
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                backgroundColor:
                  themeMode === IMIThemeMode.LIGHT
                    ? ThemeColors.bottomNavColor.light
                    : ThemeColors.bottomNavColor.dark,
              },
            },
          },
          MuiBottomNavigationAction: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  color:
                    themeMode === IMIThemeMode.LIGHT
                      ? ThemeColors.bottomNavSelectColor.light
                      : ThemeColors.bottomNavSelectColor.dark,
                },
              },
            },
          },
        },
      }),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <ImiAppBars>
        <Routes>
          <Route path="/" element={<Navigate to={ImiPathsUrls.home} />} />
          <Route path={ImiPathsUrls.home} element={<ImiHome />} />
          {/* <Route path={ImiPathsUrls.search} element={<ImiSearch />} /> */}
          <Route path={ImiPathsUrls.profile} element={<ImiProfile />} />
          <Route path={ImiPathsUrls.subs} element={<ImiSubscriptions />} />
          <Route path={ImiPathsUrls.game} element={<ImiGameFrame />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ImiAppBars>
    </ThemeProvider>
  );
}

export default App;
